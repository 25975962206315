import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, TextField } from '@mui/material'
import { TRACK_ORDER_VALIDATION } from 'utils/Validation/trackOrderForm-validation'
import { Container, Wrapper, InputWrapper, ErrorMessage, ButtonSection } from 'styles/TrackOrderModal'
import { getOrderDetailsRequest } from 'api/orders.api'

const TrackOrderModal = ({ onClose }) => {
  const [apiError, setApiError] = useState('')
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(TRACK_ORDER_VALIDATION),
  })
  const history = useHistory()

  const onSubmit = async (data) => {
    try {
      setApiError('') // Clear previous error message

      // Call the API to check if the order ID exists
      const response = await getOrderDetailsRequest(data?.trackingNumber)

      if (response && !response.error && response[0].id) {
        // If the response contains the order details, navigate to the track order page
        history.push(`/track-order/${data?.trackingNumber}`)
        onClose() // Close modal only on success
      } else {
        // Handle case where order ID does not exist or error is returned
        setApiError('Order not found. Please check your order number and try again.')
      }
    } catch (error) {
      // Handle unexpected errors
      setApiError('Order not found. Please check your order number and try again.')
    }
  }

  return (
    <Container>
      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <Controller
            name="trackingNumber"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <TextField
                  type="text"
                  id="trackingNumber"
                  placeholder="Enter Tracking Number / Order Number"
                  {...field}
                  style={{ width: '100%' }}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                />
                {apiError && <ErrorMessage>{apiError}</ErrorMessage>}
                <ErrorMessage>{errors?.trackingNumber?.message}</ErrorMessage>
              </>
            )}
          />
        </InputWrapper>
        <ButtonSection>
          <Button variant="contained" type="submit">
            Track
          </Button>
        </ButtonSection>
      </Wrapper>
    </Container>
  )
}

export default TrackOrderModal
