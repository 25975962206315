import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { deleteWithAuthentication, postLoginCall } from '../../api/axios'
import {
  CardSection,
  Card,
  Description,
  Price,
  ImageSection,
  CardSectionWrapper,
  OptionWrapper,
  OutOfStockWrapper,
  WishlistWrapper,
  OrderButtonWrapper,
  DescriptionWrapper,
  EmptyWrapper,
  ProductCard,
} from 'styles/searchProducts'
import no_image_found from 'assets/images/no_image_found.png'
import WishlistIcon from 'assets/svg/BharathamWishlistIcon'
import WishlistFilledIcon from 'assets/svg/BharathamWishlistFilled'
import NoDataFound from 'views/EmptyData'
import Button from '@mui/material/Button'
import ModalComponent from 'components/common/Modal'
import PlaceOrderModal from 'views/OrderModal'
import { isLoggedIn } from 'utils/validateToken'
import LoginModal from 'views/LoginModal'
import { formatIndianRupees, getOrCreateDeviceId, getUserId } from 'helper'
import { ToastContext } from 'context/toastContext'
import { CartContext } from 'context/cartContext'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import CustomizationRenderer from 'components/application/product-list/product-details/CustomizationRenderer'
import useGet from 'hooks/useGet'
import OutOfStock from 'components/outOfStock'

export const ProductCards = ({ products, setProducts }) => {
  const history = useHistory()
  const [orderModal, setOrderModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [loginModal, setLoginModal] = useState(false)
  const [buyNow, setBuyNow] = useState(false)
  const [buttonDisable, setButtonDisable] = useState(false)
  const [loading, setLoading] = useState({})
  const [deviceId, setDeviceId] = useState('')
  const [customization_state, setCustomizationState] = useState({})
  const { fetchCartItems, cartItems } = useContext(CartContext)
  const dispatch = useContext(ToastContext)

  useEffect(() => {
    if (products && products.length > 0) {
      const buttonStates = products.reduce((acc, product) => {
        const inCart = cartItems.some((item) => item.item.id === product.id)
        acc[product.id] = inCart
        return acc
      }, {})

      setButtonDisable(buttonStates)
    }
  }, [cartItems, products])

  const handleBuyNowClick = (product) => {
    ReactGA.event({
      category: 'Search',
      action: 'Click',
      label: 'Buy Now',
    })

    setSelectedProduct(product)

    if (!isLoggedIn()) {
      setBuyNow(true)
      setLoginModal(true)
      return
    } else {
      setOrderModal(true)
    }
  }

  const userId = getUserId()

  useEffect(() => {
    let id = ''
    const fetchId = async () => {
      id = await getOrCreateDeviceId()
      setDeviceId(id)
    }

    fetchId()
  }, [])

  const {
    refetch: fetchWishlist,
    // data: wishlistData
  } = useGet('wishlist', deviceId && `/clientApis/v2/wishlist/${userId}/${deviceId}`)

  useEffect(() => {
    if (deviceId) fetchWishlist()
  }, [])

  let selectedCustomizationIds = []
  const getCustomization_ = (groupId) => {
    let group = customization_state[groupId]
    if (!group) return

    group.selected.map((s) => selectedCustomizationIds.push(s.id))
    group?.childs?.map((child) => {
      getCustomization_(child)
    })
  }

  const getCustomizations = (product) => {
    if (!product?.customisation_items?.length) return null
    const customizations = []

    const firstGroupId = customization_state['firstGroup']?.id

    if (!firstGroupId) return
    getCustomization_(firstGroupId)

    for (const cId of selectedCustomizationIds) {
      let c = product?.customisation_items.find((item) => item.local_id === cId)
      if (c) {
        c = {
          ...c,
          quantity: {
            count: 1,
          },
        }
        customizations.push(c)
      }
    }

    return customizations
  }

  const handleAddToCart = async (product) => {
    const userId = getUserId()
    if (buttonDisable[product.id]) return

    ReactGA.event({
      category: 'Purchase journey',
      action: 'add_to_cart',
      label: 'Add to Cart',
      value: parseFloat(product?.item_details?.price?.value || 0).toFixed(2),
      currency: 'INR',
      items: [
        {
          id: product?.id,
          name: product?.item_details?.descriptor?.name,
          price: parseFloat(product?.item_details?.price?.value || 0).toFixed(2),
          quantity: 1,
        },
      ],
    })

    setLoading((prevState) => ({ ...prevState, [product.id]: true }))
    const deviceId = await getOrCreateDeviceId()

    // const subtotal = product?.item_details?.price?.value
    const url = `/clientApis/v2/cart/${userId}/${deviceId}`
    // const subtotal = product?.item_details?.price?.value

    // Pass getCustomizations(product) directly to the payload
    const payload = {
      // item_id: product?.local_id,
      customisations: getCustomizations(product),
      hasCustomisations: !!getCustomizations(product),
      customisationState: customization_state,
      local_id: product?.local_id,
      id: product?.id,
      provider: {
        id: product?.provider_details.id,
      },
      quantity: {
        count: 1,
      },
    }

    try {
      const data = await postLoginCall(url, payload)

      if (data) {
        fetchCartItems()
        localStorage.setItem('cartItems', JSON.stringify(data))
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Item added to cart successfully.',
          },
        })
      }

      setLoading((prevState) => ({ ...prevState, [product.id]: false }))
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message:
            'Unfortunately, We’re experiencing some technical issues while adding items to your cart. Please bear with us & get back to us sometime.',
        },
      })
    }
  }

  const handleWishlist = async (product, e) => {
    const userId = getUserId()
    e.stopPropagation()
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    }

    const deviceId = await getOrCreateDeviceId()

    const { item_details } = product

    const url = `/clientApis/v2/wishlist/${userId}/${deviceId}`
    // const subtotal = product?.item_details?.price?.value

    // Pass getCustomizations(product) directly to the payload
    const payload = {
      local_id: product?.local_id,
      id: product?.id,
      provider: {
        id: product?.provider_details?.id,
      },
      quantity: {
        count: 1,
      },
    }

    try {
      setProducts((prevItems) =>
        prevItems.map((item) => (item.item_details.id === item_details?.id ? { ...item, wishlistAdded: true } : item)),
      )
      const res = await postLoginCall(url, payload)
      if (res.status !== 'error') {
        localStorage.setItem('wishlistItems', JSON.stringify(res))
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.success,
            message: 'Item added to wishlist successfully.',
          },
        })
      } else {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: 'It looks like the item already exists in the Wishlist. Please check',
          },
        })
      }
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Sorry, you cannot add this item to your Wishlist due to technical glitch. Please try again.',
        },
      })
    }
  }

  const handleRemoveFromWishlist = async (item_details, e) => {
    const userId = getUserId()
    e.stopPropagation()
    if (!isLoggedIn()) {
      setLoginModal(true)
      return
    }

    try {
      const deviceId = await getOrCreateDeviceId()
      const url = `/clientApis/v2/item/wishlist/${userId}/${deviceId}/${item_details?.id}`
      await deleteWithAuthentication(url)

      setProducts((prevItems) =>
        prevItems.map((item) => (item.item_details.id === item_details?.id ? { ...item, wishlistAdded: false } : item)),
      )

      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: 'Item removed from your Wishlist',
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'Sorry, we hit a snag while fetching the products. Please try again.',
        },
      })
    }
  }

  return (
    <CardSectionWrapper>
      {products && products?.length > 0 ? (
        <CardSection>
          {products?.map((product, index) => {
            const { item_details } = product
            const productId = item_details?.slug
            const currentProductValue = parseFloat(product?.item_details?.price?.value || 0)
            const formattedCurrentProductValue = currentProductValue.toFixed(2)
            return (
              <ProductCard key={index}>
                <Card onClick={() => history.push(`/products/${productId}`)}>
                  <OptionWrapper>
                    {product?.item_details?.quantity?.available?.count === '0' ? (
                      <OutOfStockWrapper onClick={(e) => e.stopPropagation()}>
                        <OutOfStock />
                      </OutOfStockWrapper>
                    ) : (
                      ''
                    )}
                    <WishlistWrapper>
                      {product.wishlistAdded ? (
                        <WishlistFilledIcon
                          onClick={async (e) => {
                            handleRemoveFromWishlist(item_details, e)
                          }}
                        />
                      ) : (
                        <WishlistIcon onClick={(e) => handleWishlist(product, e)} />
                      )}
                    </WishlistWrapper>
                  </OptionWrapper>
                  <ImageSection>
                    <img
                      src={
                        product?.item_details?.descriptor?.symbol
                          ? product?.item_details?.descriptor?.symbol
                          : no_image_found
                      }
                      alt={product?.item_details?.descriptor?.name}
                      onError={(e) => {
                        e.target.src =
                          'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                      }}
                    />
                  </ImageSection>
                </Card>
                <DescriptionWrapper>
                  <Description>
                    {product?.item_details?.descriptor?.name.length > 40
                      ? product?.item_details?.descriptor?.name.substring(0, 40) + '...'
                      : product?.item_details?.descriptor?.name}
                  </Description>
                  <Price>{`₹${formatIndianRupees(formattedCurrentProductValue)}`}</Price>
                  <OrderButtonWrapper>
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleAddToCart(product)
                        setButtonDisable((prev) => ({ ...prev, [product?.id]: true }))
                      }}
                      disabled={
                        product?.item_details?.quantity?.available?.count === '0' ||
                        buttonDisable[product?.id] ||
                        loading[product?.id]
                      }
                    >
                      {buttonDisable[product?.id] ? 'In Cart' : 'Add to Cart'}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleBuyNowClick(product)
                      }}
                      disabled={product?.item_details?.quantity?.available?.count === '0'}
                    >
                      Buy Now
                    </Button>
                  </OrderButtonWrapper>
                </DescriptionWrapper>
              </ProductCard>
            )
          })}
        </CardSection>
      ) : (
        <EmptyWrapper>
          <NoDataFound content={'No Product Found'} />
        </EmptyWrapper>
      )}
      {orderModal && (
        <ModalComponent
          open={orderModal}
          onClose={() => setOrderModal(false)}
          title="Get Ready To Shop !"
          titleBg={true}
        >
          <PlaceOrderModal onClose={() => setOrderModal(false)} product={selectedProduct} />
        </ModalComponent>
      )}

      {loginModal && (
        <ModalComponent open={loginModal} onClose={() => setLoginModal(false)}>
          <LoginModal onClose={() => setLoginModal(false)} buyNow={buyNow} setOrderModal={setOrderModal} />
        </ModalComponent>
      )}

      <CustomizationRenderer customization_state={customization_state} setCustomizationState={setCustomizationState} />
    </CardSectionWrapper>
  )
}
